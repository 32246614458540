import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image";
import StyledButton from '../../components/Button';
import ContactFormCom from '../../components/ContactFormCom';
import SingleOffersGallery from '../../components/SingleOffersGallery';
import Offers from '../../components/Offers';
import SEO from '../../components/SEO';

const HeroStyledWrapper = styled.div`
  position: relative;
  height: 30vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: 100px;
`;

const HeroStyledHeaders = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

const HeroStyledHeader = styled.h1`
  position: relative;
  z-index: 9997;
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.font.size.m};
  font-family: 'Dancing Script', cursive;
  line-height: 2.2rem;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.xxxl};
  }
`;

const HeroStyledImage = styled(GatsbyImage)`
  position: relative;
  height: 30vh;
  width: 100%;
  opacity: 0.8;
`;

const TextStyledWrapper = styled.div`
  position: relative;
  text-align: center;
  margin: 50px;
  font-size: ${({ theme }) => theme.font.size.xs};

  ${({ theme }) => theme.mq.desktop} {
    margin: 50px 300px 0 300px;
    font-size: ${({ theme }) => theme.font.size.s};
  }
`;

const OffersWrapper = styled.div`
  background-color: ${({ theme }) => theme.primaryFloristic};
  padding: 2rem;
  margin: 2rem 0;
  text-align: center;
  position: relative;
`;

const StyledTitle = styled.h2`
  font-weight: 600;
  font-size: ${({ theme }) => theme.font.size.s};
  text-transform: uppercase;
  text-shadow: 1px 1px 2px lightgrey;
  font-family: 'Dancing Script', cursive;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

const StyledTitleOffers = styled(StyledTitle)`
  padding: 1.5rem 0 0 0;
  text-align: center;
`;

const DescriptionWrapper = styled.div`
  line-height: 2.2rem;
  max-width: 800px;
  margin: 2em auto;
`;

const StyledH3 = styled.h3`
  color: #59388f;
  text-decoration: underline;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

const StyledParagraph = styled.p`
  margin: 4rem 0;
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: 'Dancing Script', cursive;
  line-height: 4rem;
  font-size: ${({ theme }) => theme.font.size.s};

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

export default function WiankiNaGlowe({ data }) {
  return (
    <>
      <SEO title="Florystyka ślubna, bukiety ślubne, kwiaty na wesele" />
      <HeroStyledWrapper>
        <HeroStyledHeaders>
          <HeroStyledHeader>{data.singleOffer.title}</HeroStyledHeader>
        </HeroStyledHeaders>
        <HeroStyledImage image={data.singleOffer.image.asset.gatsbyImageData} />
      </HeroStyledWrapper>
      <TextStyledWrapper>
        <DescriptionWrapper>
          <StyledParagraph>
            <b>Ślub – jeden z najważniejszych dni w naszym życiu.</b>
          </StyledParagraph>
          <StyledParagraph>
            To wtedy chcemy wyglądać pięknie i czuć się jak w bajce. Na taką
            cudowną chwilę składa się wiele rzeczy m.in. suknia ślubna,
            biżuteria, fryzjer, kosmetyczka ( nie pomijając oczywiście Pana
            Młodego :) ) oraz wszystkie dekoracje nas otaczające. No i właśnie
            tutaj wkraczamy MY. Czym byłoby takie wydarzenie bez pięknego
            bukietu ślubnego lub łapiącej za serce dekoracji w kościele lub na
            stołach?
            <StyledParagraph>
              Do każdej realizacji <b>podchodzimy indywidualnie</b> i staramy
              się dopasować tak, aby tworzyło jedność. Ważny przy wyborze
              bukietu ślubnego jest charakter Panny Młodej, wygląd a nawet krój
              i kolor sukni ślubnej. Istotą jest także dobór odpowiednich
              kwiatów oraz wielkość samego bukietu.{' '}
              <b>U nas nie ma rzeczy niemożliwych.</b> Stajemy zawsze na
              wysokości zadania i staramy się zadowolić nawet najbardziej
              wymagającą klientkę.{' '}
            </StyledParagraph>
            W naszych bukietach można odnaleźć:{' '}
            <StyledList>
              <li>kwiaty standardowe (dostępne przez cały rok)</li>
              <li>kwiaty sezonowe</li>
              <li>kwiaty letnie</li>
              <li>kwiaty polne</li>
              <li>sukulenty</li>
            </StyledList>
            <StyledParagraph>
              Dodatkiem do bukietu ślubnego może być{' '}
              <b>
                bransoletka z kwiatów lub dekoracyjny grzebyk wpleciony we włosy
              </b>
              . Coraz modniejsze stają się{' '}
              <Link to="/oferta/wianki-na-glowe">wianki na głowę</Link> z
              kwiatów lub samej zieleni zastępując tradycyjny welon.
            </StyledParagraph>
            <StyledParagraph>
              {' '}
              <Link to="/oferta/wianki-na-glowe">
                <b>Sprawdź nasze wianki na głowę!</b>
              </Link>
              .
            </StyledParagraph>
          </StyledParagraph>
          <StyledParagraph>
            Uzupełnieniem Pana Młodego jest oczywiście{' '}
            <b>butonierka wczepiana za pomocą agrafki w klapę marynarki</b>.
            Stanowi ona w minimalistycznej odsłonie integralne połączenie z
            bukietem Pani Młodej. Idealna także dla Świadków oraz Ojców Pary
            Młodej.
          </StyledParagraph>
          <StyledParagraph>
            Zajmujemy się również{' '}
            <b>
              dekoracjami sal weselnych, ślubów w plenerze, dekoracji kościoła
              oraz samochodów
            </b>
            . Każda praca stworzona przez Nas nawiązuje do charakteru miejsca
            oraz całej ceremonii zaślubin. Uwzględniamy predyspozycje Pary
            Młodej co do wyboru kolorów oraz motywu przewodniego jak i również
            pory roku. Tym mniej zdecydowanym chętnie pomagamy oraz doradzamy
            tak, aby sprostać ich oczekiwaniom.
          </StyledParagraph>
          <StyledParagraph>
            Jeżeli marzysz o pięknej dekoracji to zapraszam Cię na spotkanie ze
            Mną przy dobrej kawie i ciachu.
          </StyledParagraph>
          <StyledH3>OFERUJEMY</StyledH3>
          <StyledList>
            {data.singleOffer.listOfOffers.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </StyledList>
          <ButtonWrapper>
            <StyledButton as={Link} to="/kontakt">
              Skontaktuj się z nami
            </StyledButton>
          </ButtonWrapper>
        </DescriptionWrapper>
      </TextStyledWrapper>
      <OffersWrapper>
        <StyledTitleOffers>nasze realizacje</StyledTitleOffers>
        <SingleOffersGallery offerTitle="Florystyka ślubna" />
        <ButtonWrapper>
          <StyledButton
            className="white"
            as={Link}
            to="/galeria/florystyka-slubna"
          >
            Zobacz więcej zdjęć
          </StyledButton>
        </ButtonWrapper>
      </OffersWrapper>
      <ContactFormCom />
      <OffersWrapper>
        <StyledTitleOffers>sprawdź nasze inne oferty</StyledTitleOffers>
        <Offers />
      </OffersWrapper>
    </>
  );
}

export const query = graphql`
  query FlorystykaSlubnaQuery {
    singleOffer: sanityOffer(slug: { current: { eq: "florystyka-slubna" } }) {
      image {
        asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      id
      listOfOffers
      title
      headline
      description
    }
  }
`;
